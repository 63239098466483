<template>
    <div class="item-label">
        <div class="item-icon-box">
            {{label}}
            <el-popover v-if="content!=null&&content!=''" placement="top-start" title="填写指南" width="200"
                        trigger="hover" :content="content">
                <span slot="reference"
                      style="user-select:none;display:inline-block;width:14px;height:14px;line-height:14px;text-align:center;font-size:12px;border-radius:7px;background:#ccc;color:#fff;margin-left:4px;">
                    ?
                </span>
            </el-popover>
        </div>
        <div class="item-icon-box">
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            "label",
            "content"
        ],
    }
</script>

<style scoped lang="scss">
    .item-label {
        display: inline-block;
        .item-icon-box {
        }
    }
</style>